import { useAuth0LoginMutation, useEmailLoginMutation, useInitCsrf } from "hooks/auth"
import { GetServerSidePropsContext } from "next"
import dynamic from "next/dynamic"
import Head from "next/head"
import { SubmitHandler, useForm } from "react-hook-form"
import { checkAuth } from "utility/auth"

type FormInput = {
  email: string
}

const LoginLogo = dynamic(() => import("components/login/login-logo"), {
  ssr: false,
})

export default function Login() {
  useInitCsrf()

  const emailLogin = useEmailLoginMutation()
  const auth0Login = useAuth0LoginMutation({ callBackUrl: null })

  const { register, handleSubmit } = useForm<FormInput>()

  const onSubmit: SubmitHandler<FormInput> = (data) => emailLogin.mutate(data.email)

  return (
    <>
      <Head>
        <title>Login - Setpoint</title>
      </Head>
      <div className="flex min-h-screen flex-col justify-center bg-sp-taupe py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <LoginLogo />
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <form
            className="mt-5 sm:flex sm:items-center"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="w-full sm:max-w-sm">
              <label htmlFor="email" className="sr-only">
                Email
              </label>
              <input
                type="email"
                className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-sp-cta focus:ring-sp-cta sm:text-sm`}
                placeholder="Sign in with your work email"
                {...register("email", { required: true })}
              />
            </div>
            <button
              type="submit"
              className="mt-3 inline-flex w-full items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-sp-cta px-4 py-2 font-medium text-white shadow-sm hover:bg-sp-cta-600  focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Sign In
            </button>
          </form>
          <div className="relative py-6">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
              <div className="w-full border-t border-sp-forest" />
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="bg-sp-taupe px-2 text-sp-forest">Or</span>
            </div>
          </div>
          <div>
            <form
              onSubmit={() => {
                auth0Login.mutate()
              }}
            >
              <button
                type="submit"
                className="flex w-full justify-center rounded-md border border-transparent bg-sp-cta py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sp-cta-600 focus:outline-none "
              >
                Sign in with SSO
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
  return await checkAuth(context)
}
